import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
// =========import-loader==========
import PropagateLoader from "react-spinners/PropagateLoader"; //react-spinners
// ==========import-css-files===========
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; //bootstrap-style-sheet
import './assets/css/style.css'; //main-style-sheet
import './assets/css/color.css'; //color-style-sheet
import './assets/css/responsive.css'; //responsive-style-sheet
// ==========import-js-files===========
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';//bootstrap-js-file
import './assets/js/fontawesome-all.min.js'; //fontawesome-icons-js-file
// ========Import-Pages=========
import Header from "./Components/header"; // Header
import Home from "./pages/home"; // Home-page
const About = React.lazy(() => import('./pages/about')); // About-page
const Property = React.lazy(() => import('./pages/property')); // Property-page
const Addproperty = React.lazy(() => import('./pages/addproperty')); // Add-Property-page
const Blog = React.lazy(() => import('./pages/blog')); // Blog-page
const Blogdetail = React.lazy(() => import('./pages/blogdetail')); // Blogdetail-Page
const Contact = React.lazy(() => import('./pages/contact')); // Contact-page
const Postdetail = React.lazy(() => import('./pages/postdetail')); // Postdetail-Page
const Pricing = React.lazy(() => import('./pages/pricing')); // Pricing-Page
const Login = React.lazy(() => import('./pages/login')); // Login-Page
const Signup = React.lazy(() => import('./pages/signup')); // Signup-Page
const Message = React.lazy(() => import('./pages/message')); // Message-Page
const Profile = React.lazy(() => import('./pages/profile')); // Profile-Page
import Footer from "./Components/footer"; // Footer


function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000);
  }, []);
  return (
    <>
      {/* loader */}
      {loading ? (
        <div className='loader'>
          <PropagateLoader color={'#ffffff'} loading={loading} size={20} />
        </div>
      ) : (
        <>
          <Suspense fallback={
            <div className='loader'>
            <PropagateLoader color={'#ffffff'} loading={loading} size={20} />
          </div>
          }>
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/property" element={<Property />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/postdetail" element={<Postdetail />} />
                <Route path="/addproperty" element={<Addproperty />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/blogdetail" element={<Blogdetail />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/message" element={<Message />} />
                <Route path="/profile" element={<Profile />} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </Suspense>
        </>
      )}
    </>
  );
}

export default App;
