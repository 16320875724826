import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import CountUp from 'react-countup'; //CountUp
import Feedback from "../Components/clientsfeedback" //Component
import Deals from "../Components/newestdeals" //Component
const home = () => {

  const [counterState, setCounterState] = useState(false); //CountUp
  function setscroll() {
    if (window.scrollY > onscroll) {
      setCounterState(true);
    } else {
      setCounterState(false);
    }
  }
  window.addEventListener("scroll", setscroll); //scroll-countup

  return (
    //home-page
    <>
      <section>
        <div className="top-poster">
          <figure>
            <img src="images/resources/top-poster.jpg" alt="img" />
          </figure>
          <div className="container">
            <div className="poster-data">
              <h1>Invest today
                <span>in  You <span>Dream Home.</span></span></h1>
              <p>Unlock the Power of Real Estate Making Your Real Estate
                Dreams a Reality Real Estate here.</p>
              <NavLink to="/about">
                <button>make an enquiry</button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* top-poster-section-end */}
      <Deals />
      {/* newest-deals-section-end */}
      <section>
        <div className="gap">
          <div className="container">
            <div className="hading">
              <h1>Building Facilities</h1>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="facilit-data">
                  <h1>Making living spaces More Beautiful.</h1>
                  <p>Over 39,000 people work for us in more than 70
                    countries all over the This breadth of global coverage
                    combined with specialist services.</p>
                  <div className="facilit-item-data">
                    <div className="facilit-item">
                      <div>
                        <span><i className="fa-regular fa-house"></i></span>
                        <main>Smart Home Design</main>
                      </div>
                      <div>
                        <span><i className="fa-regular fa-mountain"></i></span>
                        <main>Beautiful Scene </main>
                      </div>
                    </div>
                    <div className="facilit-item">
                      <div>
                        <span><i className="fa-regular fa-heart-pulse"></i></span>
                        <main>Exceptional Lifestyle</main>
                      </div>
                      <div>
                        <span><i className="fa-regular fa-badge-check"></i></span>
                        <main>Complete Secu</main>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <figure className="facilit-img-card">
                  <img src="images/resources/facilit-img-card.jpg" alt="img" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* building-facilities-section-end */}
      <section>
        <div className="gap">
          <div className="container">
            <div className="hading">
              <h1>Fun Facts</h1>
            </div>
          </div>
          <div className="fun-cont-main">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="fun-cont">
                    <div>
                      <i className="fa-regular fa-location-dot"></i>
                      <h1>{counterState && <CountUp start={0} end={560} duration={5} delay={0} />}+</h1>
                      <span>Total Area Sq</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="fun-cont">
                    <div>
                      <i className="fa-regular fa-building-columns"></i>
                      <h1>{counterState && <CountUp start={0} end={197} duration={5} delay={0} />}K+</h1>
                      <span>apartments</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="fun-cont">
                    <div>
                      <i className="fa-regular fa-excavator"></i>
                      <h1>{counterState && <CountUp start={0} end={268} duration={5} delay={0} />}+</h1>
                      <span>Constructions</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="fun-cont">
                    <div>
                      <i className="fa-regular fa-loveseat"></i>
                      <h1>{counterState && <CountUp start={0} end={450} duration={5} delay={0} />}+</h1>
                      <span>Apartio Rooms</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* fun-facts-section-end */}
      <Feedback />
      {/* clients-feedback-section-end */}
    </>
  )
}

export default home
