import React from 'react'
import { NavLink } from 'react-router-dom'
const footer = () => {
  const handleSubmit = (event) => {
    event.preventDefault(); // equivalent to onsubmit="return false"
    // add your form submission logic here
  };
  return (
    //footer-section
    <>
      <footer>
        <div className="gap">
          <div className="footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="foot-data">
                    <NavLink to="/">
                      <div className="foot-logo">
                        <img src="images/resources/logo.png" alt="img" />
                        <h3>arse</h3>
                      </div>
                    </NavLink>
                    <p>Contact us or visit our office for more information, you will surely be happy with our services.</p>
                    <div className="foot-address">
                      <i className="fa-solid fa-location-dot"></i>
                      <div>
                        <h5>Address</h5>
                        <span>Brooklyn, New York, United states.</span>
                      </div>
                    </div>
                    <div className="foot-address">
                      <i className="fa-solid fa-envelope"></i>
                      <div>
                        <h5>example@example.com</h5>
                      </div>
                    </div>
                    <div className="foot-address">
                      <i className="fa-solid fa-phone"></i>
                      <div>
                        <h5>012 345 678 9101</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="services-list">
                    <ul className="ser-list">
                      <h3>services</h3>
                      <li><NavLink to="/property">Reliable Rentals</NavLink></li>
                      <li><NavLink to="/property">Golden Key Properties</NavLink></li>
                      <li><NavLink to="/property">Swift Home Sales</NavLink></li>
                      <li><NavLink to="/property">Elite Realty Services</NavLink></li>
                      <li><NavLink to="/property">dream property solutions</NavLink></li>
                      <li><NavLink to="/property">Terms & Conditions</NavLink></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="subscribe">
                    <h3>subscribe</h3>
                    <form onSubmit={handleSubmit}>
                      <input type="email" placeholder="Your mail address" />
                      <button><i className="fa-solid fa-paper-plane"></i></button>
                    </form>
                    <p>Stay informed with a concise daily briefing
                      politics, culture, tech, and more, delivered strai
                      to your inbox.</p>
                    <div className="foot-ico">
                      <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                      <a href="#"><i className="fa-brands fa-whatsapp"></i></a>
                      <a href="#"><i className="fa-brands fa-x-twitter"></i></a>
                      <a href="#"><i className="fa-brands fa-instagram"></i></a>
                      <a href="#"><i className="fa-brands fa-youtube"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <span>&#169; All Rights Reserved Company 2024</span>
            </div>
          </div>

        </div>
      </footer>
    </>
  )
}

export default footer
