import React from 'react'
import OwlCarousel from 'react-owl-carousel'; //owl-carousel
import 'owl.carousel/dist/assets/owl.carousel.css'; //owl-carousel
import 'owl.carousel/dist/assets/owl.theme.default.css'; //owl-carousel
const clientsfeedback = () => {
      // =========OwlCarousel-settings=======
    const rating = {
        items: 4,
        loop: true,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 2000,
        smartSpeed: 2000,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 2,
          },
          600: {
            items: 2,
    
          },
          1000: {
            items: 4,
          },
          320: {
            items: 1,
            innerWidth: "100%",
            outerWidth: "100%"
          },
          280: {
            items: 1,
            innerWidth: "100%",
            outerWidth: "100%"
          },
        },
      };
    return (
        //clients-feedback-section
        <>
            < section >
                <div className="gap">
                    <div className="container">
                        <div className="hading">
                            <h1>Clients Feedback</h1>
                        </div>
                        <OwlCarousel className="main-feed-card" {...rating}>
                            <div className="feed-card">
                                <figure>
                                    <img src="images/resources/f-dp-1.jpg" alt="img" />
                                </figure>
                                <h4>Olivia</h4>
                                <p>We are quite satisfied with the services of this platform.</p>
                                <div className="feed-ico">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                            </div>
                            <div className="feed-card">
                                <figure>
                                    <img src="images/resources/f-dp-2.jpg" alt="img" />
                                </figure>
                                <h4>Adam Joseph</h4>
                                <p>We are quite satisfied with the services of this platform.</p>
                                <div className="feed-ico">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                            </div>
                            <div className="feed-card">
                                <figure>
                                    <img src="images/resources/f-dp-3.jpg" alt="img" />
                                </figure>
                                <h4>Lsabella</h4>
                                <p>We are quite satisfied with the services of this platform.</p>
                                <div className="feed-ico">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                            </div>
                            <div className="feed-card">
                                <figure>
                                    <img src="images/resources/f-dp-4.jpg" alt="img" />
                                </figure>
                                <h4>Michael</h4>
                                <p>We are quite satisfied with the services of this platform.</p>
                                <div className="feed-ico">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                            </div>
                            <div className="feed-card">
                                <figure>
                                    <img src="images/resources/f-dp-5.jpg" alt="img" />
                                </figure>
                                <h4>Thomas</h4>
                                <p>We are quite satisfied with the services of this platform.</p>
                                <div className="feed-ico">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section >
        </>
    )
}

export default clientsfeedback
