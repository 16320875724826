import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Dealsapi from '../jsonapi/dealsapi.json'; //api
import "slick-carousel/slick/slick.css"; //slick-carousel
import "slick-carousel/slick/slick-theme.css"; //slick-carousel
import Slider from "react-slick"; //slick-carousel


//slick-carousel
function Arrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    />
  );
}

const newestdeals = () => {
  // =========slick-carousel-settings=======
  const details = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    swipeToSlide: true,
    pauseOnHover: true,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          nextArrow: false,
          prevArrow: false
        }
      }
    ]
  };
  const [dealData, setDealData] = useState(Dealsapi);
  return (
    //newest-deals-section
    <>
      <section>
        <div className="gap">
          <div className="container">
            <div className="hading">
              <h1>newest deals</h1>
            </div>
            <Slider {...details}>
              {dealData && dealData.slice(0, 6).map((curElem) => {
                const { id, image, title, price } = curElem;
                return (
                  <NavLink to="/postdetail" key={id}>
                    <div className="deals">
                      <figure>
                        <img src={image} alt="img" />
                      </figure>
                      <div className="deal-data">
                        <h4>{title}</h4>
                        <h1>{price}</h1>
                      </div>
                    </div>
                  </NavLink>
                )
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  )
}

export default newestdeals
