import React from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'

const header = () => {
    const [fix, setFix] = useState(false);
    function setFixed() {
        if (window.scrollY >= 90) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);
    // on scroll fix header function animation 
    const [openMenu, setOpenMenu] = useState(false); // responsive menu
    
    return (
        //header
        <>
            <header className={fix ? "minheader hadfixed" : "minheader"}>
                <div className="container">
                    <div className={openMenu ? "hadpar resphad" : "hadpar"}>
                        <div className="had-active-icons">
                            <div onClick={() => setOpenMenu(true)}><i className="fa-solid fa-bars" ></i></div>
                            <div onClick={() => setOpenMenu(false)}><i className="fa-solid fa-xmark"></i></div>
                        </div>
                        <div className="header-1 ">
                            <nav>
                                <NavLink to="/">
                                    <div className="head1-logo">
                                        <img src="images/resources/logo.png" alt="img" />
                                        <h3>arse</h3>
                                    </div>
                                </NavLink>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/about">About</NavLink></li>
                                    <li><NavLink to="/property">property</NavLink></li>
                                    <li><NavLink to="/pricing">pricing</NavLink></li>
                                    <li><NavLink to="/blog">blog</NavLink></li>
                                    <li><NavLink to="/contact">contact</NavLink></li>
                                </ul>
                            </nav>
                            <div className="login-signup">
                                <NavLink to="/message">
                                    <i className="fa-regular fa-comment"></i>
                                    <span>1</span>
                                </NavLink>
                                <div className="login-signup-data">
                                    <span><i className="fa-regular fa-user"></i></span>
                                    <ul>
                                        <li><NavLink to="/login">login</NavLink></li>
                                        <li><NavLink to="/signup">sign up</NavLink></li>
                                        <li><NavLink to="/profile">my profile</NavLink></li>
                                        <li><NavLink to="/addproperty">add property</NavLink></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default header
